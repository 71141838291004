import React, { createContext, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useMeQuery, User } from "../generated/graphql";
type ContextProps = {
  me?: User
};
type Selected = {
  [key: string]: string
}
// ----------------------------------------------------------------------------
// Context / Provider / Consumer
// ----------------------------------------------------------------------------
export const DataContext = createContext<Partial<ContextProps & RouteComponentProps>>({});;

const ContextProvider = ({ children, location, history }) => {
  // const [selected, setSelected] = useState<Selected>({});
  // const [config, setConfig] = useState<Configuration>();
  // const { data: collectionData } = useCollectionsQuery();
  const { data: meData } = useMeQuery();

  // useEffect(() => {
  //   if (selected && Object.keys(selected).length > 0) {
  //     if (!localStorage.hasOwnProperty('selected')) {
  //       localStorage.setItem('selected', JSON.stringify(selected));
  //     } else {
  //       if (JSON.parse(localStorage.getItem('selected')) !== selected) {
  //         localStorage.setItem('selected', JSON.stringify(selected));
  //       }
  //     }
  //   } else {
  //     if (localStorage.hasOwnProperty('selected')) {
  //       setSelected(JSON.parse(localStorage.getItem('selected')));
  //     }
  //   }
  // }, [selected]); // eslint-disable-line

  // useEffect(() => {
  //   if (config) {
  //     if (!localStorage.hasOwnProperty('config')) {
  //       localStorage.setItem('config', JSON.stringify(config));
  //     } else {
  //       if (JSON.parse(localStorage.getItem('config')) !== config) {
  //         localStorage.setItem('config', JSON.stringify(config));
  //       }
  //     }
  //   } else {
  //     if (localStorage.hasOwnProperty('config')) {
  //       setConfig(JSON.parse(localStorage.getItem('config')));
  //     }
  //   }
  // }, [config]); // eslint-disable-line

  // if (
  //   config === undefined
  //   && Object.keys(selected).length === 0
  //   && (location.pathname === '/selection/result' || location.pathname === '/selection/filter')
  //   && !localStorage.hasOwnProperty('config')
  // ) {
  //   history.push('/')
  // }

  return (
    <DataContext.Provider
      value={{
        me: meData && meData.me
      }}
    >
      {children}
    </DataContext.Provider>
  )
};

export const DataProvider = ContextProvider;

export const ContextConsumer = DataContext.Consumer;
