import React, { Suspense, useState } from 'react'
import Card from 'antd/es/card';
import List from 'antd/es/list';
import { Remove } from './Remove';
import {
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons';

import styles from './styles.module.scss';
import { useUsersQuery } from '../../../generated/graphql';
import Loader from '../../../Components/Loader';
import { Role } from './Role';
import { Session } from './Session';
import Register from '../../../Components/Register';
import { Button } from 'antd';

export const User: React.FC = () => {
  const { data, refetch } = useUsersQuery();
  const [open, set] = useState(false);

  return (
    <Card
      title={"Users"}
      headStyle={{
        fontWeight: "bold",
        fontSize: "1.3rem",
        color: "#003363"
      }}
      className={styles.content}
      bordered={false}
      extra={
        <Button shape={"circle"} className={styles.button} type="primary" onClick={() => set(c => !c)}>
          {!open ? <PlusOutlined translate={'open create'} /> : <MinusOutlined translate={'close create'} />}
        </Button>
      }
    >
      {open && <Register />}
      <Suspense fallback={<Loader />}>
        {data && <List
          bordered
          dataSource={data.users}
          renderItem={item => (
            <List.Item
              actions={[<Role user={item} refetch={refetch} />, <Session user={item} refetch={refetch} />, <Remove user={item} refetch={refetch} />]}
            >
              {item.name}
            </List.Item>
          )}
        />}
      </Suspense>
    </Card>
  );
}