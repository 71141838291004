import React, { FormEvent } from "react";
import Input from "../Input";
import styles from "./styles.module.scss";
import { useRegisterMutation } from "../../generated/graphql";
import Loader from "../Loader";

const { useState } = React;

const Register: React.FC = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [register, { data, loading, error }] = useRegisterMutation();
  const role = "Customer";

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log("form submitted");
    await register({
      variables: {
        name,
        password,
        role
      }
    });
  };

  return (
    <form className={styles.registerContainer} onSubmit={handleSubmit}>
      {!loading && !data && (
        <>
          <Input
            value={name}
            required={true}
            placeholder={"Your Name"}
            type={"text"}
            setValue={setName}
          />

          <Input
            value={password}
            required={true}
            placeholder={"Your Password"}
            type={"password"}
            setValue={setPassword}
          />

          <button
            type={"submit"}
            className={styles.button}
            onClick={handleSubmit}
          >
            Register
          </button>
        </>
      )}
      {loading && (
        <Loader />
      )}
      {!loading && !!data && (
        <div><p>Vielen Dank für Ihre Registrierung!</p><p>Sie können sich nun einloggen.</p></div>
      )}
      {!loading && error && (
        <div>Hoppla, da ist etwas schief gelaufen.</div>
      )}
    </form>
  );
};

export default Register;
