import React from 'react';

import './loader.css';

interface loaderProps {

}

const Loader: React.FC<loaderProps> = () => {
  return (
    <div className="spinner-outer">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  );
};

export default Loader;