import React from "react";
import styles from "./styles.module.scss";

interface inputProps {
  id?: string
  value: string,
  setValue: Function,
  placeholder: string,
  type: string,
  required?: boolean,
  pattern?: string
}

const Input: React.FC<inputProps> = ({ id = "", value, setValue, placeholder, type, required = false, pattern = undefined }) => {
  return (
    <input
      id={id}
      value={value}
      required={required}
      pattern={pattern}
      placeholder={placeholder}
      type={type}
      className={styles.input}
      onChange={e => setValue(e.target.value)}
    />
  );
};

export default Input;
