import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styles from "./styles.module.scss";
import { Typography } from "antd";

const { Title } = Typography;

// interface Props { }

const Home: React.FC<RouteComponentProps> = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <img src={"/logo.png"} alt={"BattleBot Logo"} />
        <Title className={styles.headline}>
          Battle Bot coming soon&trade;
        </Title>
      </div>
    </div>
  );
};

export default Home;