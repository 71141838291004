import React from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { DataProvider } from './Context/DataContext'
import Home from "./Pages/Home/";
import { Admin } from "./Admin";
// import Preselection from "./Pages/Preselection";
// import Result from "./Pages/Result";
// import Selection from "./Pages/Selection";
// import Filter from "./Pages/Filter";

export const Routes: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <DataProvider location={location} history={history}>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/admin/:id" component={Admin} />
        </Switch>
      </div>
    </DataProvider>
  );
};
