import React, { Suspense } from 'react'
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Typography from 'antd/es/typography';
import Loader from '../../../Components/Loader';
import styles from './styles.module.scss';
import { useUsersQuery } from '../../../generated/graphql';
import {
  SyncOutlined,
} from '@ant-design/icons';

const { Title } = Typography;

export const Dashboard: React.FC = () => {
  const { data: userData, refetch: refetchUsers, loading: loadingUsers } = useUsersQuery({ fetchPolicy: "network-only" });

  // useEffect(() => {
  //   if (!loadingFiles && filesData && filesData.files) {
  //     const { files, collections } = filesData.files;

  //     setFilesL(files.length + collections.length);
  //     setColL(collections.length);
  //   }
  // }, [loadingFiles, filesData])

  return (
    <Row gutter={[32, 32]} className={styles.row}>
      <Col span={8} className={styles.col}>
        <Card
          title={"Alliances"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"sync"} spin={false} onClick={() => { }} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            <Suspense fallback={<Loader />}>
              <Title className={styles.headline}>{0}</Title>
            </Suspense>
          </div>
        </Card>
      </Col>

      <Col span={8} >
        <Card
          title={"Bases"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"sync"} spin={false} onClick={() => { }} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            <Suspense fallback={<Loader />}><Title className={styles.headline}>{0}</Title></Suspense>
          </div>
        </Card>
      </Col>

      <Col span={8} >
        <Card
          title={"Users"}
          headStyle={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            color: "#003363"
          }}
          extra={<SyncOutlined translate={"sync"} spin={loadingUsers} onClick={() => refetchUsers()} className={styles.link} />}
          className={styles.content}
          bordered={false}
        >
          <div className={styles.center}>
            <Suspense fallback={<Loader />}>
              <Title className={styles.headline}>{userData && userData.users.length}</Title>
            </Suspense>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
