import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from './Routes';
// import useDimensions from "./Hooks/useDimensions";

export const Browser: React.FC = () => {
  // const { width, height } = useDimensions(window);
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};
