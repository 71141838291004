import React, { useState, useEffect } from "react";
import { setAccessToken } from "./accessToken";
import Loader from "./Components/Loader";
import "./App.css";
import { Browser } from "./Browser";

interface Props {
  backendHost: string
}

export const App: React.FC<Props> = ({ backendHost }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${backendHost}refresh_token`, {
      method: "POST",
      credentials: "include"
    }).then(async x => {
      const { accessToken } = await x.json();
      setAccessToken(accessToken);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, []); // eslint-disable-line

  if (loading) {
    return <div className="loadingContainer"><Loader /></div>;
  }

  return <Browser />;
};
