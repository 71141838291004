import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as Logo } from '../logo.svg';
import { Dashboard } from './Components/Dashboard';
import { User } from './Components/User';
import Login from '../Components/Login';
import {
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  DownSquareOutlined,
  UserOutlined
} from '@ant-design/icons';
import Button from 'antd/es/button';
import Layout from 'antd/es/layout';
import Menu from 'antd/es/menu';
import Modal from 'antd/es/modal';
import styles from './styles.module.scss';
import { getAccessToken, setAccessToken } from '../accessToken';
import { useLogoutMutation } from '../generated/graphql';
import { DataContext } from '../Context/DataContext';

const { Header, Sider } = Layout;

export const Admin: React.FC<RouteComponentProps> = ({ location }) => {
  const { me } = useContext(DataContext);
  const [logout, { client }] = useLogoutMutation();
  const [openLogin, setOpenLogin] = useState(true);
  const isLoggedIn: boolean = getAccessToken().length > 0 ? true : false;

  useEffect(() => {
    if (me && me.role !== "Admin") {
      setOpenLogin(true)
    }
    if (!me) {
      setOpenLogin(true)
    }
  }, [me]);

  return (
    <Layout>
      {me && me.role === "Admin" && (
        <>
          <Sider trigger={null} collapsible className={styles.sider}>
            <div className={styles.logo} ><Link to={{ pathname: '/' }}>
              <img src={"/logo.png"} alt={"BattleBot Logo"} width={44} />
            </Link></div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
              <Menu.Item key="/admin" icon={<Link to={{ pathname: '/admin' }}><DashboardOutlined translate={"dashboard"} /></Link>}>
                <Link to={{ pathname: '/admin' }}>Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="/admin/battles" icon={<Link to={{ pathname: '/admin/battles' }}><DownSquareOutlined translate={"battles"} /></Link>}>
                <Link to={{ pathname: '/admin/battles' }}>Battles</Link>
              </Menu.Item>
              <Menu.Item key="/admin/alliances" icon={<SettingOutlined translate={"alliances"} />}>
                <Link to={{ pathname: '/admin/alliances' }}>Alliances</Link>
              </Menu.Item>
              <Menu.Item key="/admin/users" icon={<UserOutlined translate={"users"} />}>
                <Link to={{ pathname: '/admin/users' }}>Users</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className={styles.contentLayout}>
            <Header className={styles.header} style={{ padding: 0 }}>
              <Button
                type="primary"
                className={styles.logout}
                shape="circle"
                onClick={async () => {
                  await logout();
                  setAccessToken("");
                  await client!.resetStore();
                }}
                icon={<LogoutOutlined translate={'logout'} />}
              />
            </Header>
            <Switch location={location} >
              <Route exact path='/admin' component={Dashboard} />
              <Route exact path='/admin/battles' component={Dashboard} />
              <Route exact path='/admin/alliances' component={Dashboard} />
              <Route exact path='/admin/users' component={User} />
            </Switch>
          </Layout>
        </>
      )}
      {!isLoggedIn && (
        <Modal
          title="Administration Login #1"
          visible={openLogin}
          footer={null}
        >
          <Login close={() => setOpenLogin(false)} />
        </Modal>
      )}
      {me && me.role !== "Admin" && (
        <Modal
          title="Administration Login #2"
          visible={true}
          footer={null}
        >
          <div className={styles.errorRole}>
            <p>Sie haben keine Berechtigung für die Administration!</p>
            <Link to={{ pathname: "/" }}>Zurück zur Startseite</Link>
          </div>
        </Modal>
      )}
    </Layout >
  );
}
